import { setSession } from '../redux/slices/utils';
// import { PATH_AUTH } from '../routes/paths';
import goToDashboard from './navigation/goToDashboard';
// import { isAMC } from './getUserType';

export default function handleLogoutRedirect() {
  // const isActuallyAMC: any = isAMC();
  setSession(null);
  goToDashboard('/logout', false);
  /*
  if (
    window.location.pathname &&
    window.location.pathname !== '' &&
    window.location.pathname !== '/' &&
    window.location.pathname !== '/auth/login' &&
    window.location.pathname !== '/auth/sso' &&
    window.location.pathname !== '/auth/sso/'
  ) {
    window.location.replace(
      `${PATH_AUTH.login}?redirect=${window.location.pathname}`
    );
  } else if (window.location.pathname !== '/auth/login') {
    window.location.replace(PATH_AUTH.login);
  }
    */
  // No more redirecting to The Dashboard on logout
  // if (!isEmployee() && isActuallyAMC) {
  //   window.location.replace(PATH_HOME.agentLogout);
  // }
}

import NProgress from 'nprogress';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import React, {
  Suspense,
  Fragment,
  useEffect,
  useMemo,
  ReactNode
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
// component
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { lazyWithRetry } from './utils';
import { checkTokensMatch } from '../redux/slices/authJwt';
import AuthGuard from '../guards/AuthGuard';
import { PATH_PAGE, PATH_AUTH } from './paths';
import ProfileRoutes from './profiles.routes';
import OverviewRoutes from './overview.routes';
import WizardRoutes from './wizard.routes';
import WizardMarketingClubRoutes from './wizardMarketingClub.routes';
import WizardFirstCampaign from './wizardFirstCampaign.routes';
import AgentRoutes from './agent.routes';
import MarketingRoutes from './marketing.routes';
import { MIconButton } from '../components/@material-extend';
import goToDashboard from '../utils/navigation/goToDashboard';
// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props: RouteProps) {
  // Start the page loading state
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // We used to have check tokens match here to verify that the logged in user
  // the matched the current user.  This is to prevent anyone but mainly
  // employees not be logged in as a customer and an employee.  However,
  // just by importing the root state here caused the entire route tree
  // to be re-rendered whenever there was a change in tokens.  It was not
  // an issue with useEffect that could be solved with another hook like
  // useCallback or useMemo. Just by importing RootState and isAuthenticated
  // from redux causes a re-render from a state change.

  // DO NOT IMPORT REDUX STATE HERE, BUT FARTHER DOWN IN CHILDREN COMPONENTS

  useEffect(() => {
    const storageAccessToken = localStorage.getItem('accessToken');
    const storageRefreshToken = localStorage.getItem('refreshToken');
    if (storageAccessToken && storageRefreshToken) {
      dispatch(checkTokensMatch(storageAccessToken, storageRefreshToken));
    }

    const alreadyAlerted = localStorage.getItem('alreadyErrorAlerted');
    const numOfRefreshes = localStorage.getItem('numOfRefreshes');
    if (Number(numOfRefreshes) > 0 && alreadyAlerted !== 'true') {
      localStorage.setItem('alreadyErrorAlerted', 'true');
      enqueueSnackbar('Something went wrong. Please refresh and try again', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)} />
        )
      });
    }
  }, []);

  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes: RouteItem[] = []) {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {routes.map((route, idx) => {
          const Component = route?.component;
          const Guard = route?.guard || Fragment;
          const Layout = route?.layout || Fragment;

          return (
            <RouteProgress
              key={`routes-${idx}`}
              path={route?.path}
              exact={route?.exact}
              render={(props: any) => (
                <Guard>
                  <Layout>
                    {route && route.routes ? (
                      renderRoutes(route?.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

type RouteItem = {
  exact?: boolean;
  guard?: ({ children }: { children: ReactNode }) => JSX.Element;
  path?: string | string[];
  component?: any;
  layout?: ({ children }: { children: ReactNode }) => JSX.Element;
  routes?: {
    component: any;
    path?: string | string[];
    exact?: boolean;
  }[];
};

const routes: RouteItem[] = [
  // Others Routes
  {
    exact: true,
    guard: AuthGuard,
    path: [PATH_AUTH.login],
    component: lazyWithRetry(() => {
      const redirect = goToDashboard('/login/');
      if (redirect) {
        return import('../views/Nothing');
      }
      return import('../views/authentication/Login');
    })
  },
  {
    exact: true,
    path: PATH_AUTH.loginUnprotected,
    component: lazyWithRetry(() => import('../views/authentication/Login'))
  },
  {
    exact: true,
    guard: AuthGuard,
    path: PATH_AUTH.sso,
    component: lazyWithRetry(() => import('../views/SSO'))
  },
  {
    exact: true,
    path: PATH_AUTH.register,
    component: lazyWithRetry(() => import('../views/authentication/Register'))
  },
  {
    exact: true,
    path: PATH_AUTH.registerUnprotected,
    component: lazyWithRetry(() => import('../views/authentication/Register'))
  },
  {
    exact: true,
    path: PATH_AUTH.forgotPassword,
    component: lazyWithRetry(() => {
      const redirect = goToDashboard('/wp-login.php?action=lostpassword');
      if (redirect) {
        return import('../views/Nothing');
      }
      return import('../views/authentication/ForgotPassword');
    })
  },
  {
    exact: true,
    path: PATH_AUTH.updateForgotPassword,
    component: lazyWithRetry(
      () => import('../views/authentication/ResetPassword')
    )
  },
  {
    exact: true,
    path: PATH_AUTH.setPassword,
    component: lazyWithRetry(
      () => import('../views/authentication/SetPassword')
    )
  },
  {
    exact: true,
    path: PATH_AUTH.orderForm,
    component: lazyWithRetry(() => import('../views/authentication/OrderForm'))
  },
  {
    exact: true,
    path: PATH_PAGE.page401,
    component: lazyWithRetry(() => import('../views/Page401'))
  },
  {
    exact: true,
    path: PATH_PAGE.page404,
    component: lazyWithRetry(() => import('../views/Page404'))
  },
  {
    exact: true,
    path: PATH_PAGE.page500,
    component: lazyWithRetry(() => import('../views/Page500'))
  },
  {
    exact: true,
    path: PATH_PAGE.comingSoon,
    component: lazyWithRetry(() => import('../views/ComingSoon'))
  },
  {
    exact: true,
    path: PATH_PAGE.maintenance,
    component: lazyWithRetry(() => import('../views/Maintenance'))
  },
  {
    exact: true,
    path: PATH_AUTH.root,
    component: () => <Redirect to={PATH_AUTH.login} />
  },
  WizardRoutes,
  WizardMarketingClubRoutes,
  WizardFirstCampaign,
  ProfileRoutes,
  MarketingRoutes,
  OverviewRoutes,
  AgentRoutes,

  // ----------------------------------------------------------------------
  {
    component: () => <Redirect to="/404" />
  }
];

export default routes;

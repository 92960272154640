export default function goToDashboard(path: string, replace: boolean = true) {
  const url_params = new URLSearchParams(window.location.search);
  const has_bypass = url_params.has('bypass');
  const url = process.env.REACT_APP_AMC_ROOT + path;
  if (has_bypass) {
    return false;
  }

  if (replace) {
    window.location.replace(url);
  } else {
    window.location.assign(url);
  }
  return true;
}
